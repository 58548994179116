html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Eczar', serif;
  line-height: 1.7;
  text-rendering: optimizeLegibility;
  letter-spacing: -0.003em;
  line-height: 44px;
  font-size: 28px;
}

* {
  box-sizing: border-box;
}

a {
  color: #672c00;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

p {
  margin-top: 2em;
}

.detail-image {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detail-image-pad {
  padding-right: 20px;
  display: flex;
}

.sub-title {
  font-size: 0.75em;
}

.storyImage {
  border: 1px solid #ccc;
  /* display: none; */
}

@media only screen and (max-width: 600px) {
  .detail-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .detail-image-pad {
    padding-right: 0;
    padding-bottom: 20px;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Eczar', serif;
    line-height: 1.7;
    text-rendering: optimizeLegibility;
    letter-spacing: 0;
    line-height: 40px;
    font-size: 22px;
  }
}
